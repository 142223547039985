


























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
import { getCustomFieldByKey } from '~/helpers/utils';

type PromoBannersData = {
  id: number;
  code: string;
  urls: [
    {
      id: number;
      title: string;
      url: string;
      target: string;
      media_path: string;
      media_file_name: string;
    }
  ];
  custom_fields: {
    key: string;
    value_select: string;
  }[];
};

export default defineComponent({
  name: 'PromoBanners',
  props: {
    data: {
      type: Object as PropType<PromoBannersData>,
      default: {}
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const getCustomField = (key: string) => {
      return getCustomFieldByKey(props.data.custom_fields, key)?.value_select;
    };

    const getTextColorKey = (index) => {
      return `text_color_${index + 1}`;
    };

    return { isDesktop, getCustomField, getTextColorKey };
  }
});
